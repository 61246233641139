@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  /* cursor:url("https://img.icons8.com/emoji/48/000000/white-circle-emoji.png"), auto !important; */
}

/* https://img.icons8.com/puffy-filled/32/ffffff/filled-circle.png */
/* https://img.icons8.com/emoji/48/000000/white-circle-emoji.png */

.scaling{
animation: scaleon 3s ease-in-out infinite;
}

.cc-1si9v{
  opacity: 0 !important;
  display: none !important;
  transform: scale(0) !important;
}

.cc-1kqps{
  box-shadow: 0 0 20px #212121;
}

@keyframes scaleon {
    50% {
        scale: 110%;
    }
}
